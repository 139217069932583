import { FunctionComponent, useContext, useMemo } from 'react';
import { CourseSearchContext } from '../CourseSearchContext';
import { LegacySearchIndexCourse } from '@shared/types';
import { ItemList, Course as CourseSchema } from 'schema-dts';
import { useCourseVisibility } from '../../../hooks/useCourseVisibility';

const getFullCourseURL = (
    course: LegacySearchIndexCourse,
    appContext: 'marketplace' | 'minisite' | 'embed'
): string => {
    switch (appContext) {
        case 'marketplace': {
            return `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator_slug}/${course.slug}`;
        }
        case 'minisite': {
            const [protocol, domain] = (process.env.NEXT_PUBLIC_MARKETPLACE_URL || '').split('://');
            return `${protocol}://${course.educator_slug}.${domain}/${course.slug}`;
        }
        case 'embed': {
            return `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/embed/${course.educator_slug}/${course.slug}`;
        }
    }
};

const getCarouselSchema = (
    courses: Array<LegacySearchIndexCourse>,
    appContext: 'marketplace' | 'minisite' | 'embed'
): ItemList => {
    return {
        '@type': 'ItemList',
        itemListElement: courses.map((course, index) => {
            return {
                '@type': 'ListItem',
                position: index + 1,
                url: getFullCourseURL(course, appContext),
            };
        }),
    };
};

export const EducatorCoursesSchema: FunctionComponent = () => {
    const { courses } = useContext(CourseSearchContext);
    const context = useCourseVisibility();

    const schema = useMemo(() => {
        return {
            '@context': 'https://schema.org',
            '@graph': [
                getCarouselSchema(courses, context),
                ...courses
                    .map((course) => course.schema)
                    .filter((schema): schema is CourseSchema => schema !== undefined),
            ],
        };
    }, [courses, context]);

    return (
        <script
            id="educator-courses-schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(schema),
            }}
        />
    );
};
