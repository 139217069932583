import classnames from 'classnames';
import { FunctionComponent, ReactNode, useId } from 'react';
import { ModalProps } from '..';
import BlueprintIcon from '../../BlueprintIcon';
import styles from './styles.module.scss';

export type ModalContentsProps = {
    heading: string;
    contents: ReactNode;
    footer: ReactNode;
    onClose: ModalProps['onClose'];
    className?: string;
};

export const ModalContents: FunctionComponent<ModalContentsProps> = ({
    heading,
    contents,
    footer,
    onClose,
    className,
}) => {
    const id = useId();
    return (
        <article className={styles.container} aria-labelledby={`${id}-header`}>
            <header className={styles.header}>
                <button onClick={onClose} aria-label={`Close ${heading}`}>
                    <BlueprintIcon icon="Close" />
                </button>
                <h1 id={`${id}-header`}>{heading}</h1>
            </header>
            <div className={classnames(styles.contents, className)}>{contents}</div>
            <div className={styles.footer}>{footer}</div>
        </article>
    );
};
