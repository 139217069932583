import classNames from 'classnames';
import { ComponentProps, FunctionComponent } from 'react';

export type ContainerProps = ComponentProps<'div'>;

const Container: FunctionComponent<ContainerProps> = ({ className, children, ...props }) => {
    return (
        <div className={classNames('container', className)} {...props}>
            {children}
        </div>
    );
};

export default Container;
