import { SearchIndexCourseFilters } from '@shared/types';
import { QueryObject } from '../..';
import { useQueryStateMapper } from '../../../../../../hooks/useQueryState';

const FILTER_KEYS_TO_OMIT = ['educator_ref', 'context'];

export const getFilterQueryMapper: (
    educatorId: string
) => useQueryStateMapper<SearchIndexCourseFilters, QueryObject> = (educatorId) => (filters) => {
    return Object.entries(filters).reduce((queryObject, [filterKey, filterValue]): QueryObject => {
        if (FILTER_KEYS_TO_OMIT.includes(filterKey)) {
            return queryObject;
        }
        if (filterKey === 'list_refs' && Array.isArray(filterValue)) {
            return {
                ...queryObject,
                list: filterValue[0],
            };
        }
        return {
            ...queryObject,
            [filterKey]: filterValue,
        };
    }, {});
};
