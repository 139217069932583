import { List } from '@shared/types';
import { FunctionComponent } from 'react';
import ReactHTML from '../../../../components/ReactHTML';
import Button from '../../../../components/Button';
import { ArrowLeftIcon } from '../../../../components/Icons';
import styles from './styles.module.scss';

type SelectedCollectionDescriptionTypes = {
    list: List;
    clearListFilters: () => void;
};

export const SelectedCollectionDescription: FunctionComponent<
    SelectedCollectionDescriptionTypes
> = ({ list, clearListFilters }) => {
    return (
        <div className={styles.container}>
            <Button onClick={clearListFilters} className={styles.button}>
                <ArrowLeftIcon />
                View All
            </Button>
            <h1>{list.name}</h1>
            {list.description ? (
                <p>
                    <ReactHTML html={list.description} />
                </p>
            ) : null}
        </div>
    );
};
