'use client';
import { Educator } from '@shared/types';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useEducatorPageViewAnalytics } from '../../../hooks/Analytics/useEducatorPageViewAnalytics';

export type EducatorPageViewAnalyticsProps = PropsWithChildren<{
    educatorId: Educator['_id'];
}>;

export const EducatorPageViewAnalytics: FunctionComponent<EducatorPageViewAnalyticsProps> = ({
    educatorId,
    children,
}) => {
    useEducatorPageViewAnalytics({ educatorId });

    return children;
};
