import { Aggregate } from '@shared/types';
import { FunctionComponent } from 'react';
import StarRatingWithCount from '../../StarRatingWithCount';

export const ReviewAggregate: FunctionComponent<{ review_aggregate: Aggregate | undefined }> = ({
    review_aggregate,
}) => {
    if (!review_aggregate || !review_aggregate.count) {
        return null;
    }

    return <StarRatingWithCount rating={review_aggregate.rating} count={review_aggregate.count} />;
};
