import { FunctionComponent, SVGProps } from 'react';

export const ShareIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 20 20"
            enable-background="new 0 0 20 20"
            {...props}
        >
            <g id="share_1_">
                <g>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15,18H2V5h8.76l2-2H1C0.45,3,0,3.45,0,4v15c0,0.55,0.45,1,1,1h15
			c0.55,0,1-0.45,1-1V7.24l-2,2V18z M19,0h-7c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h4.59L9.29,9.29C9.11,9.47,9,9.72,9,10
			c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L18,3.41V8c0,0.55,0.45,1,1,1s1-0.45,1-1V1C20,0.45,19.55,0,19,0z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ShareIcon;
