import { SearchIndexCourseFilters } from '@shared/types';
import { useQueryStateParser } from '../../../../../../hooks/useQueryState';
import { QueryObject } from '../..';
import { parseDeliveryFilter } from '../../../../../../services/delivery';

export const getFilterQueryParser: (
    educatorId: string,
    searchContext: SearchIndexCourseFilters['context']
) => useQueryStateParser<SearchIndexCourseFilters, QueryObject> =
    (educatorId, searchContext) => (queryObject) => {
        const parsed = Object.entries(queryObject).reduce(
            (filters, [key, value]): SearchIndexCourseFilters => {
                if (key === 'delivery') {
                    return {
                        ...filters,
                        [key]: Array.isArray(value)
                            ? parseDeliveryFilter(value[0])
                            : parseDeliveryFilter(value),
                    };
                }
                if (key === 'list') {
                    return { ...filters, list_refs: Array.isArray(value) ? [value[0]] : [value] };
                }
                return filters;
            },
            { context: searchContext }
        );
        return { ...parsed };
    };
