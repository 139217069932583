import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useMemo, useRef, useState } from 'react';

export type useQueryStateMapper<
    StateType extends { [key: string]: any },
    Query extends ParsedUrlQuery,
> = (state: StateType) => Query;
export type useQueryStateParser<
    StateType extends { [key: string]: any },
    Query extends ParsedUrlQuery,
> = (queryObject: Query) => StateType;

export type useQueryStateProps<
    StateType extends { [key: string]: any },
    Query extends ParsedUrlQuery,
> = {
    mapper: useQueryStateMapper<StateType, Query>;
    parser: useQueryStateParser<StateType, Query>;
    initialValue: StateType;
};

export type useQueryStateResult<StateType extends { [key: string]: any }> = [
    StateType,
    (newState: StateType) => void,
    boolean,
];

const queryToQueryObject = (query: ParsedUrlQuery): ParsedUrlQuery => {
    return Object.entries(query)
        .filter((pair): pair is [string, string | Array<string>] => {
            const [_key, value] = pair;
            return value !== undefined;
        })
        .reduce((queryObject: ParsedUrlQuery, [key, value]): ParsedUrlQuery => {
            return {
                ...queryObject,
                [key]: value,
            };
        }, {});
};

const searchParamsToParsedURLQuery = (searchParams: URLSearchParams): ParsedUrlQuery => {
    return Array.from(searchParams.keys()).reduce((query, key) => {
        const values = searchParams.getAll(key);
        if (values.length === 1) {
            return { ...query, [key]: values[0] };
        }
        return { ...query, [key]: values };
    }, {});
};

const parsedURLQueryToSearchParams = (parsedURLQuery: ParsedUrlQuery): URLSearchParams => {
    return Object.entries(parsedURLQuery).reduce((searchParams, [key, value]) => {
        if (value === undefined) {
            return searchParams;
        }
        if (Array.isArray(value)) {
            value.forEach((arrayValue) => searchParams.append(key, arrayValue));
            return searchParams;
        }
        searchParams.set(key, value);
        return searchParams;
    }, new URLSearchParams());
};

const isSameQueryObject = <Query extends ParsedUrlQuery>(query1: Query, query2: Query): boolean => {
    return JSON.stringify(query1) === JSON.stringify(query2);
};

const getNewQuerystringObject = <Query extends ParsedUrlQuery>(
    newQuery: Query,
    previousQuery: Query,
    currentFullQuery: ParsedUrlQuery
): ParsedUrlQuery => {
    const newFullQuery = { ...currentFullQuery, ...newQuery };
    const removedKeys = Object.keys(previousQuery).filter((key) => {
        return !newQuery[key];
    });
    removedKeys.forEach((key) => {
        delete newFullQuery[key];
    });
    return newFullQuery;
};

export const useQueryState = <
    StateType extends { [key: string]: any },
    Query extends ParsedUrlQuery,
>({
    initialValue,
    parser,
    mapper,
}: useQueryStateProps<StateType, Query>): useQueryStateResult<StateType> => {
    const router = useRouter();
    const searchParams = useSearchParams();
    const query = useMemo((): ParsedUrlQuery => {
        return searchParamsToParsedURLQuery(searchParams || new URLSearchParams());
    }, [searchParams]);
    const pathname = usePathname();

    const hydrated = useRef(false);
    const [isReady, setIsReady] = useState(false);
    const [value, setValue] = useState<StateType>(initialValue);
    const previousValueRef = useRef<Query>(mapper(initialValue));

    useEffect(() => {
        if (hydrated.current === false) {
            hydrated.current = true;
            setIsReady(true);
            if (isSameQueryObject(queryToQueryObject(query), previousValueRef.current)) {
                return;
            }
            setValue(parser(queryToQueryObject(query) as Query));
        }
    }, [query, parser]);

    useEffect(() => {
        if (hydrated.current === false) {
            return;
        }
        const newMappedQuery = mapper(value);
        if (isSameQueryObject(newMappedQuery, previousValueRef.current)) {
            return;
        }
        const newQuery = getNewQuerystringObject(newMappedQuery, previousValueRef.current, query);
        previousValueRef.current = newMappedQuery;
        router.replace(`${pathname}?${parsedURLQueryToSearchParams(newQuery).toString()}`, {
            scroll: false,
        });
    }, [router, mapper, value, query, pathname]);

    return [value, setValue, isReady];
};

export default useQueryState;
