import { SearchIndexCourse, SearchIndexCourseFilters } from '@shared/types';
import { CademyError } from '@shared/domain-shared';
import { GeneralSearchError } from '../errors';
import { SearchIndexSortBy } from '../shared';
import { SearchResultCourse, convertSearchIndexCourse } from '../../convertSearchIndexCourse';

export type SearchIndexCourseResult = {
    results: Array<SearchResultCourse>;
    page: number;
    nextPage: number;
    totalPages: number;
    totalResults: number;
};

export const CourseSearch = async (
    educatorId: string,
    search: string,
    filters: SearchIndexCourseFilters,
    page: number,
    perPage: number,
    sort_by: SearchIndexSortBy = 'relevance'
): Promise<SearchIndexCourseResult> => {
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/search/educator/${educatorId}/?page=${page}&perPage=${perPage}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                search,
                filters,
                sort_by,
            }),
            credentials: 'include',
        }
    );
    if (response.ok === false) {
        if (response.headers.get('content-type') === 'application/problem+json') {
            const problemDetails = await response.json();
            const error = CademyError.fromObject(problemDetails);
            throw error;
        }
        if (response.headers.get('content-type') === 'application/json') {
            const errorDetails = await response.json();
            throw new GeneralSearchError(errorDetails.safe);
        }
        const error = await response.text();
        console.error(new Error(`${response.status} - ${error}`));
        throw new GeneralSearchError();
    }
    const result = (await response.json()) as {
        totalItems: number;
        totalPages: number;
        page: number;
        data: Array<SearchIndexCourse>;
    };
    return {
        results: result.data.map(convertSearchIndexCourse(filters)),
        page: result.page,
        nextPage:
            result.totalPages !== result.page && result.totalPages !== 0
                ? result.page + 1
                : result.page,
        totalPages: result.totalPages,
        totalResults: result.totalItems,
    };
};
