import { List, SearchIndexCourseFilters } from '@shared/types';
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, useCallback, useId, useMemo } from 'react';
import DeliveryFilter from '../../../../../components/DeliveryFilter';
import { ListCard } from '../../../../../components/ListCard';
import { RadioGroup } from '../../../../../components/RadioGroup';
import styles from './styles.module.scss';

export type CourseFiltersProps = {
    filters: SearchIndexCourseFilters;
    setFilters: (newFilters: SearchIndexCourseFilters) => void;
    lists?: Array<List>;
};

const DeliveryFilterSection: FunctionComponent<CourseFiltersProps> = ({ filters, setFilters }) => {
    const onChange = useCallback(
        (delivery: SearchIndexCourseFilters['delivery']) => {
            setFilters({ ...filters, delivery });
        },
        [filters, setFilters]
    );

    return <DeliveryFilter value={filters.delivery} onChange={onChange} />;
};

const CollectionFilter: FunctionComponent<CourseFiltersProps & { lists: Array<List> }> = ({
    filters,
    setFilters,
    lists,
}) => {
    const onChange = useCallback(
        (list: List) => {
            setFilters({ ...filters, list_refs: [list._id] });
        },
        [filters, setFilters]
    );

    const selectedList = useMemo(() => {
        return lists.find((list) => filters.list_refs?.[0] === list._id);
    }, [filters, lists]);

    return (
        <RadioGroup
            options={lists}
            aria-label="Filter by Collection"
            value={selectedList}
            onChange={onChange}
            className={styles.collectionFilter}
            comparitor={(option1, option2) => {
                console.log(option1._id, option2._id);
                return option1._id === option2._id;
            }}
        >
            {(option, isSelected) => {
                return (
                    <ListCard
                        list={option}
                        className={classnames({ [styles.selectedCollection]: isSelected })}
                    />
                );
            }}
        </RadioGroup>
    );
};

const FilterSection: FunctionComponent<{
    label: string;
    children: ReactNode;
    isVisible: boolean;
}> = ({ label, children, isVisible }) => {
    const id = useId();
    if (!isVisible) {
        return null;
    }
    return (
        <div className={styles.filterSection}>
            <label id={`${id}-label`} htmlFor={id}>
                {label}
            </label>
            <fieldset id={id} aria-labelledby={`${id}-label`}>
                {children}
            </fieldset>
        </div>
    );
};

export const CourseFilters: FunctionComponent<CourseFiltersProps> = ({
    filters,
    setFilters,
    lists,
}) => {
    return (
        <div className={styles.container}>
            <FilterSection label="Delivery Method" isVisible={true}>
                <DeliveryFilterSection filters={filters} setFilters={setFilters} />
            </FilterSection>

            {lists?.length ? (
                <FilterSection label="Collection" isVisible={true}>
                    <CollectionFilter filters={filters} setFilters={setFilters} lists={lists} />
                </FilterSection>
            ) : null}
        </div>
    );
};
