'use client';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import ButtonLink from '../../ButtonLink';
import { FunctionComponent } from 'react';
import { CommentsAltDuotoneIcon } from '../../Icons';
import styles from '../styles.module.scss';
import { useEducatorProfileLinks } from '../../../hooks/useEducatorProfileLinks';

export const ContactEducator: FunctionComponent<{ educator: PublicEducator }> = ({ educator }) => {
    const { enquiryLink } = useEducatorProfileLinks(educator.slug);
    if (educator.can_be_contacted !== true) {
        return null;
    }

    return (
        <ButtonLink
            href={enquiryLink}
            scroll={false}
            className={styles.contact_button}
            preset="primary"
        >
            <CommentsAltDuotoneIcon /> Contact
        </ButtonLink>
    );
};
