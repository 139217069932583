'use client';

import { FunctionComponent, PropsWithChildren } from 'react';
import { useIsMinisite } from '../../../contexts/AppContext/useIsMinisite';

export const MinisiteOnly: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const isMinisite = useIsMinisite();

    if (!isMinisite) {
        return null;
    }

    return children;
};
