import { CademyError } from '@shared/domain-shared';
import { SearchIndexCourseFilters } from '@shared/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GeneralSearchError } from '../errors';
import { locationFilterToGeoFilter } from '../locationFilter';
import { SearchIndexSortBy } from '../shared';
import { CourseSearch, SearchIndexCourseResult } from './index';
import { WebCourseSearchFilters } from '../course/useCourseSearch';
import { useAnalytics } from '../../../hooks/Analytics/useAnalytics';

export type useSearchEducatorProps = {
    educatorId: string;
    filters: WebCourseSearchFilters;
    search: string;
    sort?: SearchIndexSortBy;
    initialResults?: {
        results: SearchIndexCourseResult['results'];
        page: number;
        nextPage: number;
        totalResults: number;
    };
    initialError?: CademyError | null;
};

export type useSearchEducatorResult = {
    results: SearchIndexCourseResult['results'];
    error: CademyError | null;
    isLoading: boolean;
    page: number;
    nextPage: number;
    totalResults: number;
    hasMore: boolean;
    loadMore: () => void;
    setPage: (page: number) => void;
};

const useDebouncedValue = <Type>(value: Type, delay: number): Type => {
    const [debouncedValue, setDebouncedValue] = useState<Type>(value);
    const previousValueRef = useRef<Type>(value);
    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            if (JSON.stringify(previousValueRef.current) === JSON.stringify(value)) {
                return;
            }
            previousValueRef.current = value;
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(timeoutRef);
    }, [value, delay]);
    return debouncedValue;
};

export const webFiltersToSearchFilters = async (
    webFilters: WebCourseSearchFilters
): Promise<SearchIndexCourseFilters> => {
    const geo_coordinates = webFilters.location
        ? await locationFilterToGeoFilter(webFilters.location)
        : undefined;
    return {
        category_slug: webFilters.category_slug,
        delivery: webFilters.delivery,
        educator_ref: webFilters.educator_ref,
        list_refs: webFilters.list_refs,
        marketplace_tag_slug: webFilters.marketplace_tag_slug,
        context: webFilters.context,
        geo_coordinates,
    };
};

export const useSearchEducator = ({
    educatorId,
    filters,
    search,
    sort,
    initialResults,
    initialError,
}: useSearchEducatorProps): useSearchEducatorResult => {
    const analytics = useAnalytics();
    const skipNextGet = useRef<boolean>(initialResults ? true : false);
    const [results, setResults] = useState(initialResults?.results || []);
    const [isLoading, setIsLoading] = useState(false);
    const isLoadingRef = useRef<boolean>(false);
    const [page, setPageInternal] = useState<number>(initialResults?.page || 1);
    const [nextPage, setNextPage] = useState<number>(initialResults?.nextPage || 1);
    const [totalResults, setTotalResults] = useState<number>(initialResults?.totalResults || 0);
    const [hasMore, setHasMore] = useState(
        initialResults?.page !== initialResults?.nextPage || false
    );
    const [error, setError] = useState<null | CademyError>(initialError || null);

    const appliedFiltersRef = useRef<WebCourseSearchFilters>(filters);
    const appliedSearchRef = useRef<string>(search);
    const appliedSortRef = useRef<SearchIndexSortBy | undefined>(sort);
    const activeFilters = useDebouncedValue(isLoading ? appliedFiltersRef.current : filters, 300);
    const activeSearch = useDebouncedValue(isLoading ? appliedSearchRef.current : search, 300);
    const activeSort = useDebouncedValue(isLoading ? appliedSortRef.current : sort, 300);

    const getResults = useCallback(
        async (
            search: string,
            filters: WebCourseSearchFilters,
            additive: boolean,
            page: number,
            sort: SearchIndexSortBy | undefined
        ) => {
            if (isLoadingRef.current === true) {
                return;
            }
            appliedFiltersRef.current = filters;
            appliedSearchRef.current = search;
            appliedSortRef.current = sort;
            isLoadingRef.current = true;
            setIsLoading(true);
            try {
                const convertedFilters = await webFiltersToSearchFilters(filters);
                await analytics.record.event('Educator Course Search', {
                    educatorId,
                    search,
                    filters,
                    page,
                    sort,
                });
                const {
                    results,
                    page: resultPage,
                    totalResults,
                    nextPage,
                } = await CourseSearch(educatorId, search, convertedFilters, page, 12, sort);
                setPageInternal(resultPage);
                setTotalResults(totalResults);
                setNextPage(nextPage);
                setHasMore(resultPage !== nextPage);
                setError(null);
                setResults((current) => {
                    if (additive === false) {
                        return results;
                    }
                    return [...current, ...results];
                });
            } catch (error) {
                if (error instanceof CademyError) {
                    setError(error);
                } else {
                    console.error(error);
                    setError(new GeneralSearchError());
                }
            }
            isLoadingRef.current = false;
            setIsLoading(false);
        },
        [educatorId, analytics]
    );

    const loadMore = useCallback(async () => {
        return getResults(activeSearch, activeFilters, true, page + 1, activeSort);
    }, [getResults, activeSearch, activeFilters, activeSort, page]);

    useEffect(() => {
        if (skipNextGet.current === true) {
            skipNextGet.current = false;
            return;
        }
        getResults(activeSearch, activeFilters, false, 1, activeSort);
    }, [getResults, activeSearch, activeFilters, activeSort]);

    const setPage = useCallback(
        async (page: number) => {
            return getResults(activeSearch, activeFilters, false, page, activeSort);
        },
        [getResults, activeSearch, activeFilters, activeSort]
    );

    return {
        results,
        isLoading,
        page,
        nextPage,
        totalResults,
        hasMore,
        error,
        loadMore,
        setPage,
    };
};

export default useSearchEducator;
