import { List } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { CourseSearchContext } from '../CourseSearchContext';
import { CourseCollections } from '../../../pages_components/[educator_param]/courses/CourseCollections';
import { SelectedCollectionDescription } from '../../../pages_components/[educator_param]/courses/SelectedCollectionDescription';
import Button from '../../Button';
import { ArrowLeftIcon } from '../../Icons';

type CollectionsFiltersProps = {
    educator: PublicEducator;
    lists: List[];
};

export const CollectionsFilters: FunctionComponent<CollectionsFiltersProps> = ({
    educator,
    lists = [],
}) => {
    const { filters, setFilters } = useContext(CourseSearchContext);

    const selectedListRef: List['_id'] | null = useMemo(
        () => (!filters.list_refs?.length ? null : filters.list_refs[0]),
        [filters.list_refs]
    );

    const selectedList = useMemo(() => {
        if (!lists || !lists.length || !selectedListRef) return null;
        return lists.find((list) => list._id === selectedListRef);
    }, [selectedListRef, lists]);

    const clearListFilters = useCallback(
        () => setFilters({ ...filters, list_refs: [] }),
        [filters, setFilters]
    );

    if (selectedList) {
        return (
            <SelectedCollectionDescription
                list={selectedList}
                clearListFilters={clearListFilters}
            />
        );
    }

    if (selectedListRef && !selectedList) {
        return (
            <Button onClick={clearListFilters} style={{ marginBlock: 'var(--margin-bigger)' }}>
                <ArrowLeftIcon />
                View All
            </Button>
        );
    }

    return (
        <CourseCollections
            lists={lists}
            educator={educator}
            onClick={(listRef: List['_id']) => setFilters({ ...filters, list_refs: [listRef] })}
        />
    );
};
