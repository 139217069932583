'use client';

import { FunctionComponent, PropsWithChildren } from 'react';
import { useIsMarketplace } from '../../../contexts/AppContext/useIsMarketplace';

export const MarketplaceOnly: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const isMarketplace = useIsMarketplace();

    if (!isMarketplace) {
        return null;
    }

    return children;
};
