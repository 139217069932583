'use client';

import styles from './styles.module.scss';
import Image from 'next/image';
import { ReviewAggregate } from './ReviewAggregate';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { ContactEducator } from './ContactEducator';
import { Tabs, TabsProps } from './Tabs';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useIsMinisite } from '../../contexts/AppContext/useIsMinisite';
import { MarketplaceOnly } from '../AppContext/MarketplaceOnly';
import { MinisiteOnly } from '../AppContext/MinisiteOnly';
import { ViewInAdminBanner } from '../ViewInAdminBanner';
import { UserDropdown } from '../../app/components/MarketplaceShell/Header/UserDropdown';

export type EducatorProfileProps<TabKeys extends string> = PropsWithChildren<{
    educator: PublicEducator;
    tabs: TabsProps<TabKeys>['tabs'];
    activeTabKey?: TabsProps<TabKeys>['activeKey'];
}>;

export const EducatorProfile = <TabKeys extends string>({
    educator,
    tabs,
    activeTabKey,
    children,
}: EducatorProfileProps<TabKeys>) => {
    const isMinisite = useIsMinisite();

    return (
        <div
            className={classNames('container', styles.container, {
                [styles.space_block_start]: isMinisite,
            })}
        >
            <ViewInAdminBanner
                educatorId={educator._id}
                href={`${process.env.NEXT_PUBLIC_ADMIN_URL}/profile?educator-context=${educator._id}`}
            />
            <header className={styles.educator_header_section}>
                <div className={`rounded_image ${styles.cover_image}`}>
                    <Image
                        src={
                            educator.cover_url
                                ? educator.cover_url
                                : `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/educator_fallback_cover.svg`
                        }
                        alt={educator.name}
                        fill
                        priority={true}
                        sizes="(min-width: 1240px) 1200px, 100vw"
                    />
                </div>
                <div className={styles.header_nav}>
                    <div className={`rounded_image ${styles.educator_logo}`}>
                        <Image
                            src={
                                educator.logo_url
                                    ? educator.logo_url
                                    : `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/educator_fallback_logo.svg`
                            }
                            alt={`${educator.name} logo`}
                            fill
                            priority={true}
                            sizes="110px"
                        />
                    </div>
                    <div className={styles.header_content}>
                        <div className={styles.educator_name}>
                            <h1>{educator.name}</h1>
                            <ReviewAggregate review_aggregate={educator.review_aggregate} />
                        </div>
                        <MarketplaceOnly>
                            <ContactEducator educator={educator} />
                        </MarketplaceOnly>
                        <MinisiteOnly>
                            <UserDropdown educatorId={educator._id} />
                        </MinisiteOnly>
                    </div>
                </div>
                <nav className={styles.tabNav}>
                    <Tabs tabs={tabs} activeKey={activeTabKey} />
                </nav>
            </header>

            {children}
        </div>
    );
};
