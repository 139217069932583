'use client';

import { Educator } from '@shared/types';
import { usePageViewAnalytics } from '../usePageViewAnalytics';

export type UseEducatorPageViewAnalyticsOptions = {
    educatorId: Educator['_id'];
};

export const useEducatorPageViewAnalytics = ({
    educatorId,
}: UseEducatorPageViewAnalyticsOptions) => {
    usePageViewAnalytics(async (analytics, pathname, url) => {
        await analytics.record.educatorProfileView(educatorId, pathname, url);
    });
};
