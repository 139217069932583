import { useParams, usePathname } from 'next/navigation';
import { useMemo } from 'react';

type Params = Record<string, string | string[]> | null;

/**
 * Convert the resolved path to a dynamic segment string
 *
 * @example "/cademy/courses" => "/[educator_slug]/courses"
 */
export const mapSegmentNameToPathName = (path: string, params: Params): string => {
    if (!params) {
        return path;
    }

    return Object.keys(params).reduce((pageName, paramName) => {
        const paramValue = params[paramName];

        if (Array.isArray(paramValue)) {
            const catchAllValue = paramValue.join('/');
            return pageName.replace(catchAllValue, `[...${paramName}]`);
        }

        return pageName.replace(paramValue, `[${paramName}]`);
    }, path);
};

export const usePageName = (): string => {
    const pathName = usePathname();
    const params = useParams();

    return useMemo(() => {
        if (!pathName) {
            return '/';
        }

        return mapSegmentNameToPathName(pathName, params);
    }, [pathName, params]);
};
