import {
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './styles.module.scss';
import MagnifyingGlassRegularIcon from '../../Icons/MagnifyingGlassRegularIcon';
import { List } from '@shared/types';
import { CourseSearchContext } from '../CourseSearchContext';
import Button from '../../Button';
import classnames from 'classnames';
import FilterListRegularIcon from '../../Icons/FilterListRegularIcon';
import Modal from '../../Modal';
import { ModalContents } from '../../Modal/ModalContents';
import { CourseFilters } from '../../../services/search/course/components/CourseFilters';

type FiltersProps = {
    lists?: List[];
};
export const Filters: FunctionComponent<FiltersProps> = ({ lists }) => {
    const { searchTerm, setSearchTerm, filters, setFilters, clearFilters, totalResults } =
        useContext(CourseSearchContext);

    const onSearchKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    }, []);

    const [filtersShowing, setFiltersShowing] = useState(false);
    const filtersButtonRef = useRef<HTMLButtonElement | null>(null);
    const onModalClose = useCallback(() => {
        setFiltersShowing(() => false);
        if (filtersButtonRef.current) {
            filtersButtonRef.current.focus();
        }
    }, []);

    const hasFiltersApplied = useMemo(() => {
        return Object.keys(filters).length > 2;
    }, [filters]);

    return (
        <>
            <div className={styles.searchSection}>
                <div className={styles.searchInputContainer}>
                    <MagnifyingGlassRegularIcon />
                    <input
                        aria-label={`Search courses`}
                        type="text"
                        value={searchTerm}
                        onKeyUp={onSearchKeyUp}
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                </div>
                <Button
                    onClick={() => setFiltersShowing(!filtersShowing)}
                    className={classnames({ [styles.badge]: hasFiltersApplied })}
                    iconOnlyMobile={true}
                    ref={filtersButtonRef}
                >
                    <FilterListRegularIcon />
                    Filters
                </Button>
            </div>
            {filtersShowing ? (
                <Modal size="medium" onClose={onModalClose}>
                    <ModalContents
                        heading="Filters"
                        onClose={onModalClose}
                        contents={
                            <CourseFilters
                                filters={filters}
                                setFilters={setFilters}
                                lists={lists}
                            />
                        }
                        footer={
                            <div className={styles.filterModalFooterButtons}>
                                <Button onClick={clearFilters}>Clear filters</Button>
                                <Button preset="primary" onClick={onModalClose}>
                                    Show {totalResults} courses
                                </Button>
                            </div>
                        }
                    />
                </Modal>
            ) : null}
        </>
    );
};
