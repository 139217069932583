import { LegacySearchIndexCourse } from '@shared/types';
import { FunctionComponent, useId } from 'react';
import { useCourseLink } from '../../hooks/useCourseLink';
import Link from 'next/link';
import Image from 'next/image';
import { CourseTagLine } from '../CourseTagline';
import { CourseDeliveryDetails } from '../CourseDeliveryDetails';
import { CoursePrice } from '../CoursePrice';
import styles from './styles.module.scss';

export type CourseRowProps = {
    course: LegacySearchIndexCourse;
    showPricing?: boolean;
    showVATLabel?: boolean;
};

export const CourseRow: FunctionComponent<CourseRowProps> = ({
    course,
    showPricing,
    showVATLabel,
}) => {
    const id = useId();
    const courseLink = useCourseLink(
        course.educator_slug,
        course.slug,
        course.occurrence_ref || undefined
    );
    return (
        <Link
            {...courseLink}
            className={styles.courseRowlink}
            aria-labelledby={`${id}-header`}
            aria-describedby={`${id}-details`}
        >
            <article
                id={`${id}-row`}
                className={styles.courseRow}
                aria-labelledby={`${id}-header`}
                aria-describedby={`${id}-details`}
            >
                <div className={styles.courseRowImage}>
                    <Image src={course.image_url} alt={`${course.name}`} fill sizes="107px" />
                </div>
                <div className={styles.courseRowDetails}>
                    <div id={`${id}-details`} className={styles.courseRowLeftDetails}>
                        <h3 id={`${id}-header`}>{course.name}</h3>
                        <CourseDeliveryDetails course={course} />
                        <CourseTagLine course={course} />
                        {course.available_spaces === 0 ? (
                            <span className={styles.warningBadge}>Sold out</span>
                        ) : null}
                    </div>
                    {showPricing !== false ? (
                        <CoursePrice
                            course={course}
                            className={styles.coursePrice}
                            showVATLabel={showVATLabel}
                        />
                    ) : null}
                </div>
            </article>
        </Link>
    );
};
