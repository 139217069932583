import { SearchIndexCourseFilters } from '@shared/types';

export const parseDeliveryFilter = (string: string): SearchIndexCourseFilters['delivery'] => {
    if (!string) {
        return undefined;
    }
    if (string === 'in-person') {
        return string;
    }
    if (string === 'online') {
        return string;
    }
    if (string === 'live-online') {
        return string;
    }
    if (string === 'on-demand') {
        return string;
    }
};
