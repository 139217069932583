import { SearchIndexCourseFilters } from '@shared/types';
import { createContext } from 'react';
import { SearchIndexCourseResult } from '../../../services/search/course';
import { CademyError } from '@shared/domain-shared';

type CourseSearchContextType = {
    filters: SearchIndexCourseFilters;
    setFilters: (filters: SearchIndexCourseFilters) => void;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    courses: SearchIndexCourseResult['results'];
    error: CademyError | null;
    hasMore: boolean;
    isLoading: boolean;
    loadMore: () => void;
    totalResults: number;
    clearSearchTerm: () => void;
    clearFilters: () => void;
};

export const CourseSearchContext = createContext<CourseSearchContextType>({
    filters: { context: 'minisite' },
    setFilters: () => {},
    searchTerm: '',
    setSearchTerm: () => {},
    courses: [],
    error: null,
    hasMore: false,
    isLoading: false,
    loadMore: () => {},
    totalResults: 0,
    clearSearchTerm: () => {},
    clearFilters: () => {},
});
