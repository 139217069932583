'use client';

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePageName } from '../usePageName';
import { useAnalytics } from '../useAnalytics';
import { IAnalytics } from '@shared/analytics';

export type PageViewCallback = (analytics: IAnalytics, pathname: string, url: URL) => Promise<void>;

export type Options = {
    pageLoaded?: boolean;
};

const defaultOptions: Options = {
    pageLoaded: true,
};

export const usePageViewAnalytics = (
    callback: PageViewCallback,
    { pageLoaded }: Options = defaultOptions
) => {
    const pageName = usePageName();
    const analytics = useAnalytics();
    const hasRunOnCurrentPage = useRef<boolean>(false);
    const url = typeof window !== 'undefined' ? window.location.href : undefined;

    const pageViewCallback = useCallback<() => Promise<void>>(async () => {
        if (!url) {
            return;
        }

        callback(analytics, pageName, new URL(url));
    }, [pageName, callback, url, analytics]);

    const shouldRunCallback = useMemo(() => {
        if (!url) {
            return false;
        }

        if (pageLoaded === false) {
            return false;
        }

        return true;
    }, [url, pageLoaded]);

    useEffect(() => {
        if (shouldRunCallback === false || hasRunOnCurrentPage.current === true) {
            return;
        }

        hasRunOnCurrentPage.current = true;

        pageViewCallback();
    }, [shouldRunCallback, pageViewCallback]);
};
