import { SearchIndexCourseFilters } from '@shared/types';
import { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { RadioGroup } from '../RadioGroup';

export type DeliveryFilterProps = {
    value: SearchIndexCourseFilters['delivery'];
    onChange: (newValue: SearchIndexCourseFilters['delivery']) => void;
    renderOptionLabel?: (option: {
        value: NonNullable<SearchIndexCourseFilters['delivery']>;
        label: string;
        isSelected: boolean;
    }) => ReactNode;
};

const getMethodLabel = (method: SearchIndexCourseFilters['delivery']): string => {
    if (!method) {
        return '';
    }
    switch (method) {
        case 'in-person':
            return 'In-Person';
        case 'on-demand':
            return 'Online On-Demand';
        case 'online':
            return 'Online';
        case 'live-online':
            return 'Live';
    }
};

const options: Array<NonNullable<SearchIndexCourseFilters['delivery']>> = [
    'in-person',
    'online',
    'live-online',
    'on-demand',
];

export const DeliveryFilter: FunctionComponent<DeliveryFilterProps> = ({
    value,
    onChange,
    renderOptionLabel,
}) => {
    return (
        <RadioGroup<NonNullable<SearchIndexCourseFilters['delivery']>>
            className={styles.radioGroup}
            aria-label="Delivery method"
            options={options}
            onChange={onChange}
            value={value}
        >
            {(deliveryMethod, isSelected) => {
                const methodLabel = getMethodLabel(deliveryMethod);
                const label = renderOptionLabel
                    ? renderOptionLabel({
                          isSelected,
                          value: deliveryMethod,
                          label: methodLabel,
                      })
                    : methodLabel;
                const className = classNames({
                    [styles.option]: true,
                    [styles.selected]: isSelected,
                    [styles.partialSelected]:
                        value === 'online' &&
                        (deliveryMethod === 'live-online' || deliveryMethod === 'on-demand'),
                    [styles.indent]:
                        deliveryMethod === 'live-online' || deliveryMethod === 'on-demand',
                });
                return (
                    <div className={className} aria-label={methodLabel}>
                        <div className={styles.radio}>
                            <div className={styles.radioChecked}></div>
                        </div>
                        <span>{label}</span>
                    </div>
                );
            }}
        </RadioGroup>
    );
};

export default DeliveryFilter;
