import { FunctionComponent } from 'react';
import { SearchIndexCourseResult } from '../../../../services/search/searchEducator';
import styles from './styles.module.scss';
import { CourseRow } from '../../../CourseRow';

type RowsProps = {
    courses: SearchIndexCourseResult['results'];
    showPricing?: boolean;
    showVATLabel?: boolean;
};

export const Rows: FunctionComponent<RowsProps> = ({
    courses,
    showPricing = true,
    showVATLabel,
}) => {
    return (
        <ul className={styles.courseRows}>
            {courses.map((courses) => {
                return (
                    <li key={courses._id}>
                        <CourseRow
                            course={courses}
                            showPricing={showPricing}
                            showVATLabel={showVATLabel}
                        />
                    </li>
                );
            })}
        </ul>
    );
};
