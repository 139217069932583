import { SearchIndexCourseFilters } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import useSearchEducator, {
    useSearchEducatorProps,
} from '../../services/search/searchEducator/useSearchEducator';
import { CourseSearchContext } from './CourseSearchContext';
import useCourseSearch, {
    useCourseSearchProps,
} from '../../services/search/course/useCourseSearch';
import { SearchIndexSortBy } from '../../services/search/shared';
import { SearchResultCourse } from '../../services/convertSearchIndexCourse';

type EducatorCourseSearchProps = PropsWithChildren<{
    filters: SearchIndexCourseFilters;
    setFilters: (filters: SearchIndexCourseFilters) => void;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    sort?: SearchIndexSortBy;
    educator: PublicEducator;
    initialResults?: NonNullable<useSearchEducatorProps['initialResults']>;
}>;

export const EducatorCourseSearch: FunctionComponent<EducatorCourseSearchProps> = ({
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    sort,
    educator,
    initialResults,
    children,
}) => {
    const {
        results: courses,
        error,
        hasMore,
        isLoading,
        loadMore,
        totalResults,
    } = useSearchEducator({
        educatorId: educator._id,
        search: searchTerm,
        filters,
        initialResults,
        sort,
    });

    const clearSearchTerm = useCallback(() => {
        setSearchTerm('');
    }, [setSearchTerm]);

    const clearFilters = useCallback(() => {
        setFilters({ context: filters.context });
    }, [setFilters, filters]);

    const contextValue = useMemo(() => {
        return {
            filters,
            setFilters,
            searchTerm,
            setSearchTerm,
            courses,
            error,
            hasMore,
            isLoading,
            loadMore,
            totalResults,
            clearSearchTerm,
            clearFilters,
        };
    }, [
        clearFilters,
        clearSearchTerm,
        courses,
        error,
        filters,
        hasMore,
        isLoading,
        loadMore,
        searchTerm,
        setFilters,
        setSearchTerm,
        totalResults,
    ]);

    return (
        <CourseSearchContext.Provider value={contextValue}>{children}</CourseSearchContext.Provider>
    );
};

type CourseCourseSearchProps = PropsWithChildren<{
    filters: SearchIndexCourseFilters;
    setFilters: (filters: SearchIndexCourseFilters) => void;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    sort?: SearchIndexSortBy;
    onNewResults?: (newResults: Array<SearchResultCourse>) => void;
    initialResults?: NonNullable<useCourseSearchProps['initialResults']>;
    perPage: number;
}>;

export const CourseCourseSearch: FunctionComponent<CourseCourseSearchProps> = ({
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    perPage,
    sort,
    initialResults,
    onNewResults,
    children,
}) => {
    const {
        results: courses,
        error,
        hasMore,
        isLoading,
        loadMore,
        totalResults,
    } = useCourseSearch({ search: searchTerm, filters, initialResults, sort, perPage });

    useEffect(() => {
        onNewResults ? onNewResults(courses) : undefined;
    }, [courses, onNewResults]);

    const clearSearchTerm = useCallback(() => {
        setSearchTerm('');
    }, [setSearchTerm]);

    const clearFilters = useCallback(() => {
        setFilters({ context: filters.context });
    }, [setFilters, filters]);

    const contextValue = useMemo(() => {
        return {
            filters,
            setFilters,
            searchTerm,
            setSearchTerm,
            courses,
            error,
            hasMore,
            isLoading,
            loadMore,
            totalResults,
            clearSearchTerm,
            clearFilters,
        };
    }, [
        clearFilters,
        clearSearchTerm,
        courses,
        error,
        filters,
        hasMore,
        isLoading,
        loadMore,
        searchTerm,
        setFilters,
        setSearchTerm,
        totalResults,
    ]);

    return (
        <CourseSearchContext.Provider value={contextValue}>{children}</CourseSearchContext.Provider>
    );
};
