import { EventEmitter } from 'events';
import { ChangeEvent, Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Button from '../../../components/Button';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { DEFAULT_BOOSTS } from './boosts';

type DebugSearchWeightsProps = {};

export const BoostChangeEmitter = new EventEmitter();

export const DebugSearchWeights: FunctionComponent<DebugSearchWeightsProps> = () => {
    const [boosts, setBoosts] = useState(DEFAULT_BOOSTS);

    const debouncedBoosts = useDebouncedValue(boosts, 500);
    useEffect(() => {
        BoostChangeEmitter.emit('change', debouncedBoosts);
    }, [debouncedBoosts]);

    useEffect(() => {
        const localStorageBoosts = window.localStorage.getItem('search-boosts');
        if (localStorageBoosts) {
            const storedBoosts = JSON.parse(localStorageBoosts);
            const boosts = {
                ...DEFAULT_BOOSTS,
                ...storedBoosts,
            };
            setBoosts(boosts);
            BoostChangeEmitter.emit('change', boosts);
        }
    }, []);

    const onBoostChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newBoosts = { ...boosts, [event.target.name]: Number(event.target.value) };
            setBoosts(newBoosts);
            localStorage.setItem('search-boosts', JSON.stringify(newBoosts));
        },
        [boosts, setBoosts]
    );

    const onResetClick = useCallback(() => {
        setBoosts(DEFAULT_BOOSTS);
        localStorage.setItem('search-boosts', JSON.stringify(DEFAULT_BOOSTS));
    }, [setBoosts]);

    return (
        <details className={styles.container}>
            <summary>Search weights</summary>
            <form>
                {Object.entries(boosts).map(([key, value]) => {
                    return (
                        <Fragment key={key}>
                            <label htmlFor={key}>{key}</label>
                            <input
                                type="range"
                                name={key}
                                value={value}
                                min={0.01}
                                max={15}
                                step={0.01}
                                onChange={onBoostChange}
                            />
                            <input
                                id={key}
                                type="number"
                                name={key}
                                value={value}
                                onChange={onBoostChange}
                            />
                        </Fragment>
                    );
                })}
            </form>
            <Button onClick={onResetClick}>Reset</Button>
        </details>
    );
};

export default DebugSearchWeights;
