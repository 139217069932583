import { FunctionComponent, useContext, useMemo } from 'react';
import { CourseSearchContext } from '../CourseSearchContext';
import Alert from '../../Alert';
import { CademyError } from '@shared/domain-shared';

export const SearchError: FunctionComponent = () => {
    const { error } = useContext(CourseSearchContext);

    const errorText = useMemo(() => {
        if (!error) {
            return '';
        }
        if (error instanceof CademyError) {
            return error.detail || error.title;
        }
        return error;
    }, [error]);

    if (!error) return null;

    return <Alert preset="danger">{errorText}</Alert>;
};
