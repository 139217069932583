import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ParsedUrlQuery } from 'querystring';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

const extractValue = (query: ParsedUrlQuery, paramName: string): string | null => {
    const value = query[paramName];
    if (value === undefined) {
        return null;
    }
    if (Array.isArray(value)) {
        return value[0];
    }
    return value;
};

const searchParamsToParsedURLQuery = (searchParams: URLSearchParams): ParsedUrlQuery => {
    return Array.from(searchParams.keys()).reduce((query, key) => {
        const values = searchParams.getAll(key);
        if (values.length === 1) {
            return { ...query, [key]: values[0] };
        }
        return { ...query, [key]: values };
    }, {});
};

const parsedURLQueryToSearchParams = (parsedURLQuery: ParsedUrlQuery): URLSearchParams => {
    return Object.entries(parsedURLQuery).reduce((searchParams, [key, value]) => {
        if (value === undefined) {
            return searchParams;
        }
        if (Array.isArray(value)) {
            value.forEach((arrayValue) => searchParams.append(key, arrayValue));
            return searchParams;
        }
        searchParams.set(key, value);
        return searchParams;
    }, new URLSearchParams());
};

export type ReturnType = [string | null, Dispatch<SetStateAction<string | null>>, boolean];

/**
 * Gives you a useState style interface for working with a query parameter
 *
 * @param paramName the name of the query parameter
 */
export const useQueryParam = (paramName: string, initialValue: string | null): ReturnType => {
    const router = useRouter();
    const searchParams = useSearchParams();
    const query = useMemo((): ParsedUrlQuery => {
        return searchParamsToParsedURLQuery(searchParams || new URLSearchParams());
    }, [searchParams]);
    const pathname = usePathname();

    const hydrated = useRef(false);
    const [isReady, setIsReady] = useState(false);
    const [value, setValue] = useState<string | null>(initialValue || null);

    useEffect(() => {
        if (hydrated.current === false) {
            hydrated.current = true;
            setIsReady(true);
            setValue(extractValue(query, paramName));
        }
    }, [query, paramName]);

    useEffect(() => {
        if (hydrated.current === false) {
            return;
        }
        if (
            query[paramName] === value ||
            (query[paramName] === undefined && value === '') ||
            (query[paramName] === undefined && value === null)
        ) {
            return;
        }
        if (value === null || value === '') {
            const newQuery = { ...query };
            delete newQuery[paramName];
            router.replace(`${pathname}?${parsedURLQueryToSearchParams(newQuery).toString()}`, {
                scroll: false,
            });
            return;
        }
        const newQuery = { ...query, [paramName]: value };
        router.replace(`${pathname}?${parsedURLQueryToSearchParams(newQuery).toString()}`, {
            scroll: false,
        });
    }, [router, paramName, value, query, pathname]);

    return [value, setValue, isReady];
};
