'use client';
import { useMemo } from 'react';
import { EducatorProfile, EducatorProfileProps } from '../../../../components/EducatorProfile';
import { DeliveryMethod, List, SearchIndexCourseFilters } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { useSearchEducatorProps } from '../../../../services/search/searchEducator/useSearchEducator';
import { getFilterQueryParser } from './data/getFilterQueryParser';
import Container from '../../../../components/Container';
import { getFilterQueryMapper } from './data/getFilterQueryMapper';
import useQueryState from '../../../../hooks/useQueryState';
import { useQueryParam } from '../../../../hooks/useQueryParam';
import { useCourseVisibility } from '../../../../hooks/useCourseVisibility';
import { EducatorCourseSearch } from '../../../../components/EducatorCourseSearch';
import { CollectionsFilters } from '../../../../components/EducatorCourseSearch/CollectionsFilters';
import { Filters } from '../../../../components/EducatorCourseSearch/Filters';
import { SearchError } from '../../../../components/EducatorCourseSearch/SearchError';
import { LoadMoreButton } from '../../../../components/EducatorCourseSearch/LoadMoreButton';
import { EducatorCoursesSchema } from '../../../../components/EducatorCourseSearch/EducatorCoursesSchema';
import { CourseResults } from '../../../../components/EducatorCourseSearch/CourseResults';
import { SearchIndexSortBy } from '../../../../services/search/shared';

export type CoursesProps<Pages extends string> = {
    educator: PublicEducator;
    lists: Array<List>;
    initialSearch: string;
    initialFilters: SearchIndexCourseFilters;
    initialResults: NonNullable<useSearchEducatorProps['initialResults']>;
    sort?: SearchIndexSortBy;
} & {
    pages: EducatorProfileProps<Pages>['tabs'];
    activeTabKey: EducatorProfileProps<Pages>['activeTabKey'];
};

export type QueryObject = {
    delivery?: Array<NonNullable<DeliveryMethod>>;
    list?: string;
    max_price?: string;
    min_price?: string;
    type?: string;
};

export const Courses = <Pages extends string>({
    educator,
    lists,
    initialSearch,
    initialFilters,
    initialResults,
    pages,
    activeTabKey,
    sort,
}: CoursesProps<Pages>) => {
    const courseVisibility = useCourseVisibility();

    const mapper = useMemo(() => {
        return getFilterQueryMapper(educator._id);
    }, [educator]);

    const parser = useMemo(() => {
        return getFilterQueryParser(educator._id, courseVisibility);
    }, [educator, courseVisibility]);

    const [filters, setFilters] = useQueryState<SearchIndexCourseFilters, QueryObject>({
        initialValue: initialFilters,
        mapper,
        parser,
    });

    const [search, setSearch] = useQueryParam('search', initialSearch);

    return (
        <EducatorProfile educator={educator} tabs={pages} activeTabKey={activeTabKey}>
            <main>
                <Container>
                    <EducatorCourseSearch
                        filters={filters}
                        setFilters={setFilters}
                        searchTerm={search || ''}
                        setSearchTerm={setSearch}
                        educator={educator}
                        initialResults={initialResults}
                        sort={sort}
                    >
                        <EducatorCoursesSchema />
                        <CollectionsFilters educator={educator} lists={lists} />
                        <Filters lists={lists} />
                        <SearchError />
                        <CourseResults educator={educator} displayAs="grid" />
                        <LoadMoreButton />
                        <SearchError />
                    </EducatorCourseSearch>
                </Container>
            </main>
        </EducatorProfile>
    );
};
