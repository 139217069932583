import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';

export type Tab<Key extends string = string> = {
    key: Key;
    label: string;
    href: string;
};

export type TabsProps<TabKeys extends string> = {
    tabs: Tab<TabKeys>[];
    activeKey?: TabKeys;
};

export const Tabs = <T extends string>({ tabs, activeKey }: TabsProps<T>) => {
    const tabLinks = useMemo(() => {
        return tabs.map((tab, key) => (
            <li key={key} className={classNames({ [styles.activeTab]: tab.key === activeKey })}>
                <Link href={tab.href} scroll={false}>
                    {tab.label}
                </Link>
            </li>
        ));
    }, [tabs, activeKey]);

    return (
        <div className={styles.tabs}>
            <div>
                <ul>{tabLinks}</ul>
            </div>
        </div>
    );
};
