import { SearchIndexCourseFilters } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import Alert from '../../../Alert';
import Button from '../../../Button';
import { useEducatorProfileLinks } from '../../../../hooks/useEducatorProfileLinks';

type NoCoursesAlertProps = {
    search: null | string;
    filters: SearchIndexCourseFilters;
    clearSearch: () => void;
    clearFilters: () => void;
    educator?: PublicEducator;
};

export const NoCoursesAlert: FunctionComponent<NoCoursesAlertProps> = ({
    search,
    filters,
    clearSearch,
    clearFilters,
    educator,
}) => {
    const { enquiryLink } = useEducatorProfileLinks(educator ? educator.slug : '');

    return (
        <Alert preset="warning" className={styles.noResults}>
            {search && Object.keys(filters).length > 1 ? (
                <>
                    <p>No results match your search and filters</p>
                    <div className={styles.clearButtons}>
                        <Button onClick={clearSearch}>Clear search</Button>
                        <Button onClick={clearFilters}>Clear filters</Button>
                    </div>
                </>
            ) : null}
            {search && Object.keys(filters).length === 1 ? (
                <>
                    <p>No results match your search</p>
                    <div className={styles.clearButtons}>
                        <Button onClick={clearSearch}>Clear search</Button>
                    </div>
                </>
            ) : null}
            {!search && Object.keys(filters).length > 1 ? (
                <>
                    <p>No results match your filter</p>
                    <div className={styles.clearButtons}>
                        <Button onClick={clearFilters}>Clear filters</Button>
                    </div>
                </>
            ) : null}
            {!search && Object.keys(filters).length === 1 ? (
                educator ? (
                    <p>
                        It looks like {educator.name} doesn&apos;t have any courses at the moment.
                        You can try <Link href={enquiryLink}>contacting them</Link>.
                    </p>
                ) : (
                    <p>It looks like there are no courses available at the moment.</p>
                )
            ) : null}
        </Alert>
    );
};
