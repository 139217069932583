import { FunctionComponent, useContext } from 'react';
import Button from '../../Button';
import SpinnerIcon from '../../Icons/Spinner';
import { CourseSearchContext } from '../CourseSearchContext';
import styles from './styles.module.scss';

export const LoadMoreButton: FunctionComponent = () => {
    const { hasMore, isLoading, loadMore } = useContext(CourseSearchContext);

    return (
        <div className={styles.loadMoreContainer}>
            {hasMore ? (
                <Button disabled={isLoading === true} onClick={loadMore}>
                    {isLoading ? <SpinnerIcon className={styles.loadingIcon} /> : null}
                    Load More
                </Button>
            ) : null}
        </div>
    );
};
