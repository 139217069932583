'use client';
import { FunctionComponent } from 'react';
import { useIam } from '../../services/IAm/hooks';
import Alert from '../Alert';
import ButtonLink from '../ButtonLink';
import ShareIcon from '../Icons/ShareIcon';

import styles from './styles.module.scss';

type ViewInAdminBannerProps = {
    educatorId: string;
    href: string;
};
export const ViewInAdminBanner: FunctionComponent<ViewInAdminBannerProps> = ({
    educatorId,
    href,
}) => {
    const { iam, iamReady } = useIam();
    if (!iamReady || iam.authenticated !== true) {
        return null;
    }

    if (iam.educators.some((educator) => educator.id === educatorId) === false) {
        return null;
    }

    return (
        <Alert preset="info" className={styles.alert}>
            <p>You can manage this page as an admin</p>
            <ButtonLink
                href={href}
                preset="textButton"
                className={styles.button}
                target="_blank"
                rel="noopener noreferrer"
            >
                View in Admin
                <ShareIcon width={15} height={15} />
            </ButtonLink>
        </Alert>
    );
};
